import { ThemeProvider, StyleReset, Col, Row, Text, Div, Input, Button, Container, Notification, Icon } from "atomize"
import PageTransition from "gatsby-plugin-page-transitions"
import React, { useCallback, useEffect, useState } from "react"
import Footer from "../components/footer"
import "../settings/index.css"
import Header from "../components/header"
import { StaticImage } from "gatsby-plugin-image"
import SEO from "../components/seo"
import addToMailchimp from "gatsby-plugin-mailchimp"

import WaveWhite from "../images/assets/w-collaborate-white.svg"
import WaveRed from "../images/assets/w-collaborate-red.svg"
import {
    faCarrot,
    faClipboardCheck,
    faCoins,
    faExclamation,
    faHourglassHalf,
    faReceipt,
    faUsers,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import BoldText from "../components/BoldText"
import SubheaderText from "../components/SubheaderText"
import CoverWave from "../images/assets/w-come-funziona.svg"
import { Link } from "gatsby"
import { url } from "../settings/config"
import HeadingText from "../components/HeadingText"
import PrimaryButton from "../components/buttons/PrimaryButton"
let config = require("../settings/config")

const theme = config.theme

const TitleEntry = props => {
    return (
        <Div d="flex" align="center">
            <Div w="4rem" m={{ y: { xs: "1rem", lg: "0.5rem" } }}>
                {props.icon}
            </Div>
            <SubheaderText style={{ flexBasis: "80%" }}>{props.text}</SubheaderText>
        </Div>
    )
}

const SuppInput = props => {
    return (
        <Input
            type="text"
            name={props.name}
            value={props.value}
            style={{ autocomplete: "off" }}
            autoComplete="off"
            onChange={e => props.onChange(e)}
            placeholder={props.placeholder}
            m={{ y: "0.75rem" }}
            rounded="20px"
            border="2px solid"
            borderColor="gray800"
            focusBorderColor="gunmetal"
            h="3.5rem"
            hovershadow="4"
            required
        />
    )
}

const SuppStep = props => {
    return (
        <Col size={{ xs: "12", md: "4" }} p={{ t: "2rem" }}>
            <Div d="flex" justify="center">
                {props.icon}
            </Div>
            <Text
                textColor={props.textColor}
                textAlign="center"
                textSize={{ xs: "subheader", md: "title", lg: "heading" }}
                p={{ y: "0.5rem", x: "1rem" }}
            >
                {props.text}
            </Text>
        </Col>
    )
}

function Collaborate(props) {
    const [name, setName] = useState("")
    const [city, setCity] = useState("")
    const [phone, setPhone] = useState("")
    const [mail, setMail] = useState("")

    const [successToast, setShowSuccess] = useState(false)
    const [dangerToast, setShowDanger] = useState(false)

    const [text, setText] = useState("lasagne")
    const texts = ["lasagne", "torte", "brioche", "pizzette", "pagnotte", "svizzere", "cocomere", "cotolette"]

    const shuffle = useCallback(() => {
        const index = Math.floor(Math.random() * texts.length)
        setText(texts[index])
    }, [])

    useEffect(() => {
        const intervalID = setInterval(shuffle, 2000)
        return () => clearInterval(intervalID)
    }, [shuffle])

    const handleNameChange = e => {
        setName(e.target.value)
    }
    const handleCityChange = e => {
        setCity(e.target.value)
    }
    const handlePhoneChange = e => {
        setPhone(e.target.value)
    }
    const handleMailChange = e => {
        setMail(e.target.value)
    }

    const handleSubmission = e => {
        e.preventDefault()
        addToMailchimp(mail, {
            NOMELOCALE: name,
            CELLULARE: phone,
            PROVINCIA: city,
        })
            .then(data => {
                console.log(data)
                setShowSuccess(true)
            })
            .catch(() => {
                setShowDanger(true)
            })
    }

    return (
        <PageTransition>
            <ThemeProvider theme={theme}>
                <StyleReset />

                <Header />
                <SEO
                    title="Hai un'attività e vuoi collaborare? Contattaci!"
                    description="Possiedi un locale e desideri unirti a noi? Scopri come aderire al nostro progetto di gastronomia sostenibile, usare il nostro servizio è facile e veloce!"
                    pathname="business"
                />
                <Div tag="main" pos="relative" overflow="hidden">
                    <Div
                        tag="section"
                        id="merchant_form"
                        pos="relative"
                        zIndex="2"
                        bg="accent_ulight"
                        p={{
                            t: {
                                xs: "1rem",
                                sm: "2rem",
                                lg: "8rem",
                                xl: "10rem",
                            },
                        }}
                    >
                        <Container>
                            <Text tag="h1" d="none">
                                Ti piacerebbe poter guadagnare rivendendo gli avanzi di fine giornata?
                            </Text>
                            <Text tag="h2" d="none">
                                CON SQUISEAT PUOI GUADAGNARE RIVENDENDO LE ECCEDENZE!
                            </Text>
                            <Text tag="h2" d="none">
                                Niente più sprechi con Squiseat e massima flessibilità!
                            </Text>
                            <Row align="center">
                                <Col size={{ xs: "12", lg: "7", xl: "8" }}>
                                    <Text
                                        textSize={{
                                            xs: "display2",
                                            md: "display3",
                                        }}
                                        textWeight="bold"
                                        textColor="gunmetal"
                                    >
                                        Vuoi <BoldText textColor="primary">guadagnare</BoldText> con gli{" "}
                                        <BoldText textColor="primary">avanzi</BoldText>?
                                    </Text>
                                    <Div m={{ t: { xs: "1rem", lg: "3rem" }, b: { xs: "2rem", lg: "0" } }}>
                                        <TitleEntry
                                            icon={
                                                <FontAwesomeIcon
                                                    icon={faCoins}
                                                    size="3x"
                                                    color={theme.colors.accent_darker}
                                                />
                                            }
                                            text={
                                                <>
                                                    Aumenta le entrate <BoldText>senza vincoli</BoldText>
                                                </>
                                            }
                                        />
                                        <TitleEntry
                                            icon={
                                                <FontAwesomeIcon
                                                    icon={faUsers}
                                                    size="3x"
                                                    color={theme.colors.accent_darker}
                                                />
                                            }
                                            text={
                                                <>
                                                    <BoldText>Amplia la</BoldText> tua <BoldText>clientela</BoldText>
                                                </>
                                            }
                                        />
                                        <TitleEntry
                                            icon={
                                                <FontAwesomeIcon
                                                    icon={faCarrot}
                                                    size="3x"
                                                    color={theme.colors.accent_darker}
                                                />
                                            }
                                            text={
                                                <>
                                                    <BoldText>Riduci gli sprechi</BoldText> giornalieri
                                                </>
                                            }
                                        />
                                    </Div>
                                </Col>
                                <Col
                                    size={{
                                        xs: "12",
                                        sm: "8",
                                        md: "6",
                                        lg: "5",
                                        xl: "4",
                                    }}
                                    offset={{ sm: "2", md: "3", lg: "0" }}
                                    p={{ t: { xs: "2rem", lg: "0" } }}
                                >
                                    <Div rounded="xxl" border="6px solid" borderColor="gunmetal" m="1rem" bg="white">
                                        <Div p={{ x: "2rem", y: "1rem" }}>
                                            <Text
                                                m={{ b: "1rem" }}
                                                textColor="gunmetal"
                                                textSize={{
                                                    xs: "heading",
                                                    lg: "display1",
                                                }}
                                                textAlign="center"
                                                textWeight="bold"
                                            >
                                                <BoldText textColor="primary">Contattaci</BoldText> ora!
                                            </Text>

                                            <form id="supplier_submission_form" onSubmit={handleSubmission}>
                                                <SuppInput
                                                    name="name"
                                                    value={name}
                                                    onChange={handleNameChange}
                                                    placeholder="Nome del punto vendita"
                                                />
                                                <SuppInput
                                                    name="name"
                                                    value={city}
                                                    onChange={handleCityChange}
                                                    placeholder="Provincia"
                                                />
                                                <SuppInput
                                                    name="phone"
                                                    value={phone}
                                                    onChange={handlePhoneChange}
                                                    placeholder="Numero di telefono"
                                                />
                                                <SuppInput
                                                    name="mail"
                                                    value={mail}
                                                    onChange={handleMailChange}
                                                    placeholder="Indirizzo mail"
                                                />
                                                <Button
                                                    title="Conferma le informazioni"
                                                    type="submit"
                                                    m={{
                                                        t: "1.5rem",
                                                        x: "auto",
                                                    }}
                                                    p={{
                                                        x: "1.5rem",
                                                        y: "0.5rem",
                                                    }}
                                                    bg="primary"
                                                    rounded="circle"
                                                >
                                                    <Text textColor="white" textSize="subheader">
                                                        Ecco fatto
                                                    </Text>
                                                </Button>
                                            </form>
                                        </Div>
                                    </Div>
                                </Col>
                            </Row>
                        </Container>
                    </Div>

                    <Div tag="section" bg="white" pos="relative" p={{ t: { xs: "0", md: "2rem" } }} zIndex="1">
                        <Div m={{ t: { xs: "-1rem", md: "-7rem", xl: "-10rem" } }}>
                            <CoverWave className="wave" />
                        </Div>
                        <Container>
                            <HeadingText
                                textAlign={{ lg: "center" }}
                                m={{ t: { xs: "1rem", lg: "0.5rem" }, b: { xs: "2rem", md: "0" } }}
                            >
                                <BoldText textColor="primary">Sei in chiusura</BoldText>, ci sono{" "}
                                <BoldText textColor="primary">tre {text}</BoldText> che rischiano{" "}
                                <BoldText textColor="primary">la pattumiera</BoldText>.
                            </HeadingText>
                            <SubheaderText
                                textAlign={{ lg: "center" }}
                                m={{ t: { md: "1rem", lg: "3rem" }, y: { xs: "1rem", md: "0" } }}
                            >
                                Vendile su <BoldText textColor="primary">Squiseat</BoldText>!
                            </SubheaderText>
                            <SubheaderText textAlign={{ lg: "center" }} textWeight="bold">
                                Non le sprechi, ci guadagni e sei sereno.
                            </SubheaderText>
                        </Container>
                    </Div>

                    <Div tag="section">
                        <Container pos="relative" zIndex="1">
                            <Row m={{ y: { xs: "3rem", md: "8rem" } }} align="center">
                                <Col size={{ xs: "12", md: "7" }} style={{ padding: "0" }}>
                                    <HeadingText p={{ y: "0.5rem" }}>
                                        <BoldText textColor="primary">Come si fa</BoldText>? Mooolto{" "}
                                        <BoldText textColor="primary">semplice</BoldText>.
                                    </HeadingText>
                                    <SubheaderText p={{ t: { xs: "1rem", md: "2rem" } }}>
                                        <BoldText>Aggiungi qualsiasi prodotto</BoldText> dall'app e{" "}
                                        <BoldText>metti in vendita</BoldText> le porzioni che ti avanzano, fatto!
                                    </SubheaderText>
                                    <SubheaderText p={{ t: { xs: "1rem", md: "2rem" } }}>
                                        Da ora fino alla scadenza <BoldText>potrà essere acquistato</BoldText>, così non
                                        andrà sprecato niente.
                                    </SubheaderText>
                                    <SubheaderText p={{ t: { xs: "1rem", md: "2rem" } }}>
                                        Quanto ci vuole? <BoldText textColor="primary">60 secondi</BoldText>⚡️
                                    </SubheaderText>
                                </Col>
                                <Col
                                    size={{ xs: "12", md: "5" }}
                                    textSize={{ xs: "heading", md: "display2", lg: "display3" }}
                                    textAlign="right"
                                >
                                    <Div m={{ l: { xs: "52vw", md: "5vw", lg: "10vw" } }} pos="relative" zIndex="2">
                                        <Div
                                            w={{ xs: "9rem", md: "15rem", lg: "20rem" }}
                                            left={{ xs: "0", lg: "unset" }}
                                            m={{ x: { xs: "auto", lg: "unset" } }}
                                            pos="absolute"
                                            transform="rotate(35deg)"
                                        >
                                            <StaticImage
                                                src="../images/app-squiseat-locali-caricamento-lasagna.png"
                                                alt="Logo di Squiseat negativo e senza testo"
                                                placeholder="blurred"
                                                className="logo"
                                            />
                                        </Div>
                                        <FontAwesomeIcon icon={faExclamation} size="10x" color="transparent" />
                                    </Div>
                                </Col>
                            </Row>
                        </Container>
                    </Div>

                    <Div tag="section" m={{ y: "2rem" }}>
                        <Container>
                            <Row m={{ y: { xs: "3rem", md: "8rem" } }} align="center">
                                <Col
                                    textSize={{ xs: "heading", md: "display2", lg: "display3" }}
                                    size={{ xs: "12", md: "5" }}
                                    textAlign="right"
                                    order={{ xs: 2, md: 1 }}
                                >
                                    <Div m={{ l: { xs: "52vw", md: "1vw", lg: "-2vw" } }} pos="relative" zIndex="2">
                                        <Div
                                            w={{ xs: "9rem", md: "15rem", lg: "20rem" }}
                                            left={{ xs: "0", lg: "unset" }}
                                            m={{ x: { xs: "auto", lg: "unset" } }}
                                            pos="absolute"
                                            transform={{ xs: "rotate(35deg)", lg: "rotate(25deg)" }}
                                        >
                                            <StaticImage
                                                src="../images/app-squiseat-locali-sezione-ordini.png"
                                                alt="Logo di Squiseat negativo e senza testo"
                                                placeholder="blurred"
                                                className="logo"
                                            />
                                        </Div>
                                        <FontAwesomeIcon icon={faExclamation} size="10x" color="transparent" />
                                    </Div>
                                </Col>
                                <Col size={{ xs: "12", md: "7" }} order={{ xs: 1, md: 2 }} style={{ padding: "0" }}>
                                    <HeadingText p={{ y: "0.5rem" }}>
                                        E quando arriva un <BoldText textColor="primary">ordine</BoldText>?
                                    </HeadingText>
                                    <SubheaderText p={{ t: { xs: "0.5rem", md: "1.5rem" } }}>
                                        Preparalo e <BoldText textColor="primary">consegnalo al cliente</BoldText>,
                                        nient'altro!
                                    </SubheaderText>
                                    <SubheaderText>
                                        <BoldText>Il cliente verrà</BoldText> da te <BoldText>in negozio</BoldText>, ha
                                        già pagato sull'app 📱
                                    </SubheaderText>
                                    <SubheaderText p={{ t: { xs: "1rem", md: "2rem" } }}>
                                        Per <BoldText>qualsiasi problema</BoldText> non ti preccupare, puoi sempre
                                        annullare un ordine o contattarci!
                                    </SubheaderText>
                                </Col>
                            </Row>
                        </Container>
                    </Div>

                    <Div tag="section">
                        <Container pos="relative" zIndex="1">
                            <Row m={{ y: { xs: "3rem", md: "8rem" } }} align="center">
                                <Col size={{ xs: "12", md: "7" }} style={{ padding: "0" }}>
                                    <HeadingText p={{ y: "0.5rem" }}>
                                        "Ma quanto <BoldText textColor="primary">ci guadagno</BoldText>?"
                                    </HeadingText>
                                    <SubheaderText p={{ t: { xs: "1rem", md: "2rem" } }}>
                                        Ogni prodotto viene <BoldText>venduto al 50%</BoldText> del prezzo di listino.
                                    </SubheaderText>
                                    <SubheaderText p={{ t: { xs: "1rem", md: "2rem" } }}>
                                        Dalla vendita tratteniamo il 25%, mentre{" "}
                                        <BoldText textColor="primary">il 75% resta a te</BoldText>.
                                    </SubheaderText>
                                    <SubheaderText>
                                        Se i conti tornano, <BoldText>su 10€</BoldText> di prodotto a prezzo pieno{" "}
                                        <BoldText>recuperi 3.75€</BoldText>!
                                    </SubheaderText>
                                    <SubheaderText p={{ t: { xs: "1rem", md: "2rem" } }}>
                                        <BoldText>Non male</BoldText> per un prodotto che stavi per buttare, no?
                                    </SubheaderText>
                                </Col>
                                <Col
                                    size={{ xs: "12", md: "5" }}
                                    textSize={{ xs: "heading", md: "display2", lg: "display3" }}
                                    textAlign="right"
                                >
                                    <Div m={{ l: { xs: "52vw", md: "5vw", lg: "10vw" } }} pos="relative" zIndex="2">
                                        <Div
                                            w={{ xs: "9rem", md: "15rem", lg: "20rem" }}
                                            left={{ xs: "0", lg: "unset" }}
                                            m={{ x: { xs: "auto", lg: "unset" } }}
                                            pos="absolute"
                                            transform="rotate(35deg)"
                                        >
                                            <StaticImage
                                                src="../images/app-squiseat-locali-guadagno.png"
                                                alt="Logo di Squiseat negativo e senza testo"
                                                placeholder="blurred"
                                                className="logo"
                                            />
                                        </Div>
                                        <FontAwesomeIcon icon={faExclamation} size="10x" color="transparent" />
                                    </Div>
                                </Col>
                            </Row>
                        </Container>
                    </Div>

                    <Div tag="section" bg="white" pos="relative" m={{ y: "2rem" }} p={{ t: "2rem" }} zIndex="1">
                        <Container>
                            <HeadingText textAlign={{ lg: "center" }} m={{ y: "0.5rem" }}>
                                <BoldText textColor="primary">Nessun vincolo</BoldText>, nessun costo di attivazione,
                                utilizzo o termine
                            </HeadingText>
                            <Link to="#merchant_form" title="Ecco come ordinare! | Squiseat">
                                <SubheaderText m={{ t: { md: "2rem" } }} textAlign="center" textColor="gunmetal">
                                    "<BoldText textDecor="underline">Mi avete convinto!</BoldText>"
                                </SubheaderText>
                            </Link>
                        </Container>
                    </Div>
                </Div>
                <Footer />

                <Notification
                    bg="success100"
                    textColor="success800"
                    isOpen={successToast}
                    onClose={() => setShowSuccess(false)}
                    prefix={<Icon name="Success" color="success800" size="18px" m={{ r: "0.5rem" }} />}
                >
                    Invio con successo!
                </Notification>

                <Notification
                    bg="danger100"
                    textColor="danger800"
                    isOpen={dangerToast}
                    onClose={() => setShowDanger(false)}
                    prefix={<Icon name="CloseSolid" color="danger800" size="18px" m={{ r: "0.5rem" }} />}
                >
                    Errore, ricontrolla i dati!
                </Notification>
            </ThemeProvider>
        </PageTransition>
    )
}

export default Collaborate
